@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

.form-container{
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 10%;
    overflow: auto;
}

.text-user {
    display: flex;
    justify-content: center;
    text-align: center;
    background: transparent;
    font-size: 20px;
}

.userform {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #FFFFFF;
    width: 60vw;
    border: 2px solid #2E1A4A;
    border-radius: 10px;
    padding: 40px;
}

.user-image-container{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
    align-self: center;
    overflow: hidden;
}

.user-icon {
    width: 100%;
    height: auto;
    align-self: center;
    object-fit: cover;
    -o-object-fit: cover;
}

.user-image{ 
    width: 100%;
    height: auto;
    object-fit: contain;
    -o-object-fit: contain;
}

.add-user-image{
    display: center;
    align-self: center;
    &:hover{
        cursor: pointer;
    }
}

.components{
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 2vw;
}

.info-component {  
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.info-component span {
    margin-top: 1.2rem;
}

.input-component{
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    width: 70%;
}

.nome-info, .sobrenome-info, .cpf-info, .celular-info, .email-info , .senha-info  {
    color: #1D1D1D;
    max-width: 100%;
    width: 100%;
    border: 1px solid #DFE3E6;
    min-height: 3rem;
    letter-spacing: .03rem;
    outline: none;
    background: #F1F2F3;
    transition: .25s;
    border-radius: 0.5rem;
    text-indent: 1.25rem;
    font-size: 1rem;
    padding: 0;
    box-sizing: border-box;
    margin-top: 1.2rem;
}

.container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4vh;
}

.button-update{
    background: #2E1A4A;
    border: 1px solid #22211F;
    color: white;
    white-space: nowrap;
    display: grid;
    place-items: center;
    margin-top: 1.2rem;
    margin-bottom: 1rem;
    line-height: 2.75rem;
    outline: none;
    border: none;
    font-size: 1.125rem;
    cursor: pointer;
    width: 30%;
    border-radius: 0.5rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
    transition: all .3s ease;
}

.button-update:hover {
    background: #58318f;
    border: 1px solid #2E1A4A;
}

.button-delete {
    background: #f2c7c7;
    border: 2px solid rgb(249, 184, 184);
    white-space: nowrap;
    display: grid;
    place-items: center;
    margin-top: 1.2rem;
    margin-bottom: 1rem;
    line-height: 2.5rem;
    font-size: 1.125rem;
    cursor: pointer;
    outline: none;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 0.5rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
    color: black;
}

.button-delete:hover {
    background:#f19a9a;
    border: 2px solid rgb(245, 114, 114);
}

@media screen and (max-width: 720px) {
    .text-user{
        font-size: 16px;
    }

    .button-update{
        width: 100%;
    }

    .info-component span {
        font-size: 16px;
    }

    .nome-info, .sobrenome-info, .cpf-info, .celular-info, .email-info , .senha-info {
        font-size: 15px;
    }

    .userform {
        padding: 30px;
        width: 75vw;
    }

    .container-button {
        padding-bottom: 0;
        padding-top: 3vh;
    }

    .button-update {
        margin-bottom: 0;
    }
}