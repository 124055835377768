.modal-products-content {
    background-color: white;
    position: absolute;
    width: 45%;
    height: 45%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 700px) {
    .modal-products-content {
        width: 90%;
        height: 50%;
    }
}