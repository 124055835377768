.home{
    width: 95vw;
    display: flex; 
    justify-content: center;
    height: 80vh;
}

@media screen and (max-width: 770px) {
    .home{
        display: block;
    }
}
