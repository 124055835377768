.alert-component{
    position: fixed;
    top: 1rem;
}

@media screen and (max-width: 770px){
    .alert-component{
        padding: 1rem;
    }
}
