.content-wrapper {
    width: 100%;
    height: 90vh;
    border-radius: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 48px;
}

.authenticated-icon {
    margin-bottom: 24px;
}

.redirect-btn{
    background: #2E1A4A;
    border: 1px solid #22211F;
    color: white;
    white-space: nowrap;
    display: grid;
    place-items: center;
    margin-top: 1.5rem;
    line-height: 2.75rem;
    outline: none;
    border: none;
    font-size: 1.125rem;
    cursor: pointer;
    width: 30%;
    border-radius: 0.5rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
    transition: all .3s ease;
    text-decoration: none;
    font-weight: 500;
}
  
.redirect-btn:hover{
    background: #533085;
}

@media screen and (max-width: 770px) {
    .redirect-btn{
        width: 60%;
    }
  }