.policy-container{
    padding: 3rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
}

.logo-recriv{
    width: 150px;
}