.wrapper-login{
  width: 100%;
  height: 99.2vh  ;
  display: grid;
  place-items: center;
  font-family: 'Poppins', sans-serif;
  background: #F8F8FF;
}

.form-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  gap: 1rem;
  max-height: 200rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.logo-enjoy{
  height: 4.5rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  max-width: 120px;
}

.form-side{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 30vh; 
  gap: 24px;
}

.welcome-phrase{
  font-size: 2rem;
  line-height: 2rem;
  font-weight: normal;
  margin-top: 2rem;
  margin-bottom: 0;
  padding: 0;
  text-align: center;
}

.login-phrase{
  color: var(--text-gray);
  font-size: 1.10rem;
  font-weight: normal;
  margin-top: 0;
  padding: 0;
  text-align: center;
}

.login-component{
  width: 80%;
}

.login-component span{  
  font-size: 18px;
  margin: 5px;
}

.email-input-login{
  color: #1D1D1D;
  max-width: 100%;
  width: 100%;
  border: 1px solid #DFE3E6;;
  min-height: 3rem;
  letter-spacing: .03rem;
  outline: none;
  background: #F1F2F3;
  transition: .25s;
  border-radius: 0.5rem;
  text-indent: 1.25rem;
  font-size: 1rem;
  padding: 0;
  box-sizing: border-box;
}

.senha-login{
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  width: 100%;
}

.senha-input-login{
  color: #1D1D1D;
  max-width: 100%;
  width: 100%;
  border: 1px solid #DFE3E6;;
  min-height: 3rem;
  letter-spacing: .03rem;
  outline: none;
  background: #F1F2F3;
  transition: .25s;
  border-radius: 0.5rem;
  text-indent: 1.25rem;
  font-size: 1rem;
  padding: 0
}

.eye-icon{
  position: absolute;
  top: 30%;
  right: 2%;
  cursor: pointer;
}

.login-btn{
  background: #2E1A4A;
  border: 1px solid #22211F;
  color: white;
  white-space: nowrap;
  display: grid;
  place-items: center;
  margin-top: 1.5rem;
  line-height: 2.75rem;
  outline: none;
  border: none;
  font-size: 1.125rem;
  cursor: pointer;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
  transition: all .3s ease;
}

.login-btn:hover{
  background: #533085;
}

.register-link{
  color: #2E1A4A;
  text-decoration: none;
}

/* aside */
.info-side {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  padding: 2rem;
  border-radius: 2rem;
  background: url('./assets/background.jpg') repeat center center;
  /* background-position: cover; */
  background-size: cover;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .15);;
  max-height: 90rem;
}

.info-side .blockquote-wrapper {
  gap: 1rem;
  position: relative;
  padding: 1rem;
  max-width: 25rem;
  background: white;
  border-radius: 1rem;
}

.info-side .blockquote-wrapper img {
  width: 100%;
  max-width: 100%;
  border-radius: 1rem;
}

.info-side .blockquote-wrapper blockquote {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1rem;
}

.info-side .author {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
}

.info-side .author img {
  border-radius: 50%;
  width: 2.75rem;
  height: 2.75rem;
}

.info-side .author-name {
  font-weight: 600;
  color: var(--text);
}
  
@media screen and (max-width: 770px) {
  .login{
    width: 80vw;
    margin-top: 10vh;
  }

  label{
    font-size: 16px;
  }

  .form-wrapper {
      grid-template-columns: 1fr;
  }

  .info-side {
      display: none;
  }
}