.modal-background {
    background-color: rgba(63, 63, 63, 0.553);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-products-wrapper {
    background-color: white;
    position: absolute;
    width: 45%;
    height: 52%;
    max-height: 500px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 1rem;
    padding: 0.1rem;
    border: 0.15rem solid #2E1A4A;
    border-radius: 50%;
    cursor: pointer;
}

input[type="checkbox"]::before {
    content: "";
    width: 1rem;
    height: 1rem;
    clip-path: circle(40%);
    transform: scale(0);
    background-color: #2E1A4A;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.assessment-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    border-radius: 5px;
    font-weight: 500;
}

.list-item-skeleton {
    background-color: rgb(214, 214, 214);
    height: 25px;
    border-radius: 5px;
}

.modal-button-wrapper {
    width: 90%;
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 1.2rem;
}

.cancel-button {
    height: 2.2rem;
    width: 6rem;
    background-color: rgb(239, 239, 239);
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 2px rgb(194, 194, 194);
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
}

.confirm-button {
    height: 2.2rem;
    width: 6rem;
    background-color: #F0DEC1;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 2px rgb(194, 194, 194);
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
}

@media screen and (max-width: 1000px) {
    .modal-products-wrapper {
        width: 95%;
        height: 80%;
    }

    .assessment-list-item {
        height: 30px;
    }
}
  
  