.container-admin{
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 48px;
}

.filters{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.busca{
    width: 60%;
    padding-left: 0;
    position: relative;
}

.filter-select {
    position: absolute;
    border: none;
    background-color: #F0DEC1;
    top: 0;
    left: 0px;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    font-weight: 600;
    font-size: 16px;
    border-right: 1px gray solid;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.btn-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0;
    margin-left: 10px;
}

.calendar{
    border-radius: 10px;
    background: #FFFFFF;
    padding: 5px;
    margin-left: 5px;
    &:hover{
        cursor: pointer;
    }
}

.date-range{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 10px;
}

.date-range input {
    border: none;
    background-color: rgb(226, 226, 226);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.date-range span {
    padding: 5px;
    font-size: 14px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

::-webkit-calendar-picker-indicator{
    cursor: pointer;
    font-size: 16px;    
}

.date-button{
    border: none;
    background-color: rgb(226, 226, 226);
    margin-top: 5px;
    padding: 8px;
    border-radius: 10px;
    &:hover{
        cursor: pointer;
        background-color: rgb(200, 200, 200);
    }
}

.export-btn{
    text-decoration: none;
    color: grey;
    display: flex;
    justify-content: center;
    margin: 4px;
    margin-left: 10px;
    padding: 10px;
    padding-left: 15px;
    border-radius: 10px;
    &:hover{
        background: rgb(230, 230, 230);
    }
}

.card{
    height: 90vh;
    width: 90%;
    max-width: 1200px;
    overflow: auto;
    border-radius: 10px;
}

.card::-webkit-scrollbar {
    background: transparent;
  }

.table-data-admin{
    width: 100%;
    height: 100px;
    table-layout: auto;
    text-align: center;
    /* overflow: auto; */
    background-color: transparent;
}

.table-data-admin tr{
    display: flex;
    width: fit-content;
    background-color: white;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    &:first-child{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    &:last-child{
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom: 1px solid gray;
    }

}

.table-data-admin th{
    background-color: #F0DEC1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    color: gray.400;
    padding: 0.5rem;
    font-weight: bold;
    font-size: xs;
    text-transform: uppercase;
    text-align: center;
    border-top: 1px solid gray;

    &:first-child{
        border-top-left-radius: 10px;
    }
    &:last-child{
        border-top-right-radius: 10px;
    }
}

.table-data-admin td{
    border-top: 1px solid gray;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: auto;
}

.sort-icon:hover{
    cursor: pointer;
}

.table-data-admin select {
    padding: 20px;
    margin: 0;
    font-size: 16px;
    background: #FFFFFF;
}

.table-data-admin input {
    padding: 20px;
    margin: 0;
    font-size: 14px;
    display: flex;
    border: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: #FFFFFF;
}

.payment{
    padding: 20px;
    border: none;
    cursor: pointer;
    margin: 0;
}


@media screen and (max-width: 700px) {
    .filter-select {
        width: 10%;
    }
}


