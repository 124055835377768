.admin-nav-wrapper{
  width: 100vw;
  height: 10vh;
}

.admin-navbar{
  padding: 30px;
  padding-top: 15px;
  display: flex;
  align-items: center;
}

.open-sidebar-icon {
  cursor: pointer;
}

.icon-admin{
  overflow: hidden;
  width: 150px;
  height: 60px;
  margin-bottom: 10px;
}

.recriv-logo{
  object-fit: contain;
  width: 100%;
  height: 100%;

}

.sidebar {
    margin: 0;
    padding: 15px;
    width: 16%;
    top: 0;
    background-color: #FFFFFF;
    position: fixed;
    height: 100%;
    overflow: auto;
    box-shadow: 4px 10px 10px rgba(182, 182, 182, 0.382);
    z-index: 1;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .sidebar.active {
    transform: translateX(0);
  }

  .sidebar-close-btn {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 10px;
    border: none;
    background-color: transparent;
    font-size: xx-large;
    cursor: pointer;
  }
  /* Sidebar links */
  .sidebar a {
    display: flex;
    align-items: center;
    color: black;
    padding: 16px;
    text-decoration: none;
    font-weight: 600;
    gap: 10px;
    border-radius: 15px;
    margin-bottom: 2px;
    margin-left: 5px;
  }
  
  /* Active/current link */
  .sidebar a.active {
    background-color: #F0DEC1;
    color: black;
  }
  
  /* Links on mouse-over */
  .sidebar a:hover:not(.active) {
    background-color: #d9d9d9;
    color: black;
  }

  
  /* Page content. The value of the margin-left property should match the value of the sidebar's width property */
  /* div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
  } */
  
  /* On screens that are less than 700px wide, make the sidebar into a topbar */
  @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}
  }
  
  /* On screens that are less than 400px, display the bar vertically, instead of horizontally */
  @media screen and (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
  }




