 body{
  margin: 0;
} 

main{
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  overflow-x: hidden;
} 

