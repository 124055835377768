.modal {
    position: fixed;
    z-index: 1; 
    left: 25%;
    top: 23%;
    width: 50%;
}
  
.modal-content {
    background-color: #F4F7FE;
    margin: 15% auto;
    padding: 15px 40px;
    padding-bottom: 40px;
    border: 1px solid #888;
    width: 90%;
    max-width: 600px;
    height: 90%;
    border-radius: 15px;
}

.modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mail-icon {
    width: 114px;
    height: 102px;
    margin-bottom: 24px;
}

.modal-content p {
    text-align: center;
}

.btn-close-modal {
    background-color: transparent;
    border: none
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
  
.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.btn-confirmation-wrapper {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 24px;
    gap: 12px;
}

.btn-cancel-modal {
    width: 7rem;
    height: 2.5rem;
    border: 1px solid rgb(243, 197, 197);
    border-radius: 5px;
    background: rgb(249, 230, 230);
    cursor: pointer;
}

.btn-confirm-modal {
    width: 7rem;
    height: 2.5rem;
    border: 2px solid rgb(234, 181, 181);
    border-radius: 5px;
    background: rgb(248, 217, 217);
    cursor: pointer;
}

@media screen and (max-width: 770px) {
    .modal {
        width: 80%;
        left: 4.5%;
    }

    .btn-confirmation-wrapper {
        justify-content: center;
    }
  }