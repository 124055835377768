.wrapper{
  display: grid;
  place-items: center;
  font-family: 'Poppins', sans-serif;
  background: #F8F8FF;
}

.register{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 100%; 
  gap: 24px;
  margin:0;
  margin-top: 100px;
  margin-bottom: 100px;
}

.logo-enjoy-register{
  height: 4.5rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  max-width: 120px;
}
  
.welcome{
  margin: 0 0 24px 0 ;
  text-align: center;
}

.register-component{
  width: 80%;
}

.register-component span{  
  font-size: 18px;
  margin: 5px;
}

.email-input, .phone-input, .cpf-input, .name-input, .sobrenome-input, .senhaConfirmada-input{
  color: #1D1D1D;
  max-width: 100%;
  width: 100%;
  border: 1px solid #DFE3E6;;
  min-height: 3rem;
  letter-spacing: .03rem;
  outline: none;
  background: #F1F2F3;
  transition: .25s;
  border-radius: 0.5rem;
  text-indent: 1.25rem;
  font-size: 1rem;
  padding: 0;
  box-sizing: border-box;
}

.senha{
  margin: 0;
  display: flex;
  position: relative;
  width: 100%;
}

.senha-input{
  color: #1D1D1D;
  max-width: 100%;
  width: 100%;
  border: 1px solid #DFE3E6;;
  min-height: 3rem;
  letter-spacing: .03rem;
  outline: none;
  background: #F1F2F3;
  transition: .25s;
  border-radius: 0.5rem;
  text-indent: 1.25rem;
  font-size: 1rem;
  padding: 0
}

.eye-icon{
  position: absolute;
  top: 30%;
  right: 2%;
  cursor: pointer;
}

.register-btn{
  /* background: #C4A662;
  margin-top: 20px;
  padding: 16px 32px;
  border: 1px solid #a08546;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px; */
  background: #2E1A4A;
  border: 1px solid #22211F;
  color: white;
  white-space: nowrap;
  display: grid;
  place-items: center;
  margin-top: 1.5rem;
  line-height: 2.75rem;
  outline: none;
  border: none;
  font-size: 1.125rem;
  cursor: pointer;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
  transition: all .3s ease;
}

.register-btn:hover{
  background: #533085;
}

.register-link{
  font-weight: 600;
  color: #2E1A4A;
  text-decoration: none;
}

@media screen and (max-width: 770px) {
  .register{
    width: 80vw;
    margin-top: 0;
  }

  .logo-enjoy-register{
    max-width: 120px;
    position: static;
    margin-top: 1rem;
  }

  label{
    font-size: 16px;
  }
}