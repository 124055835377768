.navbar{
    display: flex;
    justify-content: space-between;
    position: fixed;
    margin: 0;
    top: 0;
    padding-top: 2vh;
    padding-right: 4vw;
    width: 100%;
    height: 10vh;   
    background-color: #F4F7FE;
}

.logo-enjoy-nav{
    margin-left: 4vw;
    max-width: 150px;
    max-height: 70px;
    &:hover{
        cursor: pointer;
    }
}

.user{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    gap: 3px;
    padding: 8px;
    border-radius: 20px;
}

.user:hover{
    background: #FFFFFF;
    cursor: pointer;
}

.menu{
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 10px 20px;
}

.menu-options{
    border-bottom: 1px solid black;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.menu li{
    list-style-type: none;
    padding: 10px; 
}

.menu li:hover{
    background: rgb(202, 202, 202);
    border-radius: 10px;
    cursor: pointer;
}

.navbar-user-icon{
    width: 2.5rem;
    height: 2.5rem;
    margin: 0;
    margin-left: 10px;
    border: 0.7px solid black;
    border-radius: 50%;
    align-self: center;
    overflow: hidden;
}

.navbar-user-image{
    width: 100%;
    height: auto;
    object-fit: contain;
    -o-object-fit: contain;
}



