.admin-assessment {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    padding-left: 15%;
}

.admin-assessment-content {
    max-width: 1800px;
    /* overflow-y: scroll; */
    margin-bottom: 2%;
}

.title-wrapper {
    width: 70%;
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.add-assessment {
    text-decoration: none;
    color: black;
    padding: 10px;
    font-weight: 600;
    background-color: #F0DEC1;
    border-radius: 5px;
    box-shadow: 1px 1px 1px rgba(182, 182, 182, 0.582);
    margin-bottom: 10px;
}

.line {
    border: 1px black solid;
    width: 70%;
}

.admin-assessment-item {
    margin-top: 15px;
    display: flex;
    width: 70%;
    height: 8vh;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid gray;
    border-radius: 15px;
    box-shadow: 5px 5px 5px rgba(182, 182, 182, 0.382);
    justify-content: space-between;
    background-color: white;
}

.date-header {
    margin-top: 40px;
}

.assessment-item-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.assessment-item-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

@media screen and (max-width: 700px) {
    .admin-assessment {
        padding-left: 8%;
    }

    .line {
        width: 85%;
    }

    .admin-assessment-item {
        width: 85%;
    }

    .title-wrapper {
        width: 85%;
        align-items: center;
        margin-top: 15px;
    }
}
