@keyframes shake {
    0% {
        margin-left: 0rem;
    }
    25% {
        margin-left: 0.5rem;
    }
    75% {
        margin-left: -0.5rem;
    }
    100% {
        margin-left: 0rem;
    }
}

.missing-input {
    animation: shake 0.3s ease-in-out 0s 2;
}

.new-assessment-container {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 15%;
}

.form-title-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
}

.arrow-back-btn {
    background-color: transparent;
    border: none;
    padding-top: 8px;
    cursor: pointer;
}

.new-assessment-form {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
}

.assessment-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.assessment-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.assessment-input-wrapper label {
    font-weight: 600;
    font-size: 17px;
}

.assessment-input-wrapper input {
    height: 4vh;
    text-indent: 10px;
    font-size: 15px;
    border-radius: 5px;
    border: 0.1px solid black;
    box-shadow: 1px 1px 3px gray;
    padding: 0;
}

.assessment-input-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
    margin-right: 10px;
}

.search-supplier-icon {
    position: absolute;
    top: 0;
    left: 96.2%;
    cursor: pointer;
    background-color: #F0DEC1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.search-supplier-icon:hover {
    background-color: #ddcbb0;
    box-shadow: 0 0 3px inset #938877;
}

.assessment-search-wrapper {
    position: absolute;
    width: 70%;
    height: 20%;
    overflow: auto;
    /* border: 1px solid black; */
}

.assessment-search-item {
    background-color: white;
    padding: 10px;
    border: 0.5px solid black;
    &:hover {
        background-color: #E9E9E9;
        cursor: pointer;
    }
}

.assessment-product-wrapper {
    display: grid;
    gap: 15px;
    flex-wrap: wrap;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
}

.add-assessment-product {
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border: 2px dashed gray;
    border-radius: 5px;
    display: flex;
    flex-basis: 32%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    background-color: #faebd4;
}

.assessment-product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    box-shadow: 1px 1px 5px gray;
    border-radius: 5px;
    flex-basis: 33%;
    padding: 10px;
    box-sizing: border-box;
}

@media screen and (max-width: 700px) {
    .form-title-wrapper{
        margin-top: 36px;
    }

    .new-assessment-container {
        padding-left: 8%;
    }

    .new-assessment-form {
        width: 85%;
    }

    .add-assessment-product {
        flex-basis: 100%
    }

    .assessment-product-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }

    .assessment-product-item {
        flex-basis: 100%;
    }

    .assessment-grid-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}