main{
    flex-direction: column;
    background: #F4F7FE;
    background-size: cover;
}

.main {
    margin-top: 20vh;
}

*:focus{
    outline: none;
}
