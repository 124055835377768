.content{
    display: flex;
    flex-direction: column;
    max-width: 1500px;
}

.values{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
}

.total, .receive{
    display: flex;
    background-color: #FFFFFF;
    padding: 20px 0;
    padding-left: 1.5rem;
    border-radius: 30px;
    align-items: center;
    margin-bottom: 20px;
}

.balance-icon, .money-icon {
    max-width: 40px;
    margin-right: 10px;
}

.total-title, .receive-title{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin: 0;
    margin-bottom: 6px;
    padding: 0;
    color: #8f8f8f;
}

.receive-value, .total-value{
    padding: 0;
    margin: 0;
    font-size: 20px;
}

.campoBusca{
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: center;
}

.buscar{
    width:100%;
}

.input-busca{
    font-size: 16px;
    line-height: 24px;
    padding: 8px 24px;
    border: 1px solid;
    border-radius: 45px;
    width: 100%;
    box-sizing: border-box;
    background: #FFFFFF;
    border-color: #FFF;
    text-align: center;
    &:focus{
        border: 1px solid grey;
    }
}

.product-table {
    /* border: 2px solid #C4A662; */
    border-radius: 10px;
    width: 100%;    
    height: 350px;
    display: flex;
    justify-content: center;
    /* overflow: auto; */
}

.logo-whatsapp{
    max-width: 50px;
    position: fixed;
    top: 92%;
    left: 95%;
}

@media screen and (max-width: 770px){
    .logo-whatsapp{
        width: 40px;
        top: 94%;
        left: 86%
    }
    
    .product-table{
        justify-content: initial;
    }

    .total, .receive{
        padding-left: 1rem;
    }
}

@media screen and (min-width: 600px) {
    .product-table{
        overflow: auto;
    }
}

@media screen and (max-width: 400px){
    .values{
        gap: 32px;
    }

    .total, .receive{
        padding-left: 1rem;
    }

    .receive-value, .total-value{
        font-size: 16px;
    }
}

