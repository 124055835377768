.table-data {
    width: 100%;
    height: 120%;
    background-color: #FFFFFF;
}

.table-data th {
    background-color: #F0DEC1;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.resizer{
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 5px;
    background-color: #27bbff;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    border-radius: 6px;
}

.resizer:hover{
    background: #2E1A4A;
    opacity: 1;
}
 
.table-data tr{
    display: flex;
    width: fit-content;
}

.table-data th {
    box-shadow: inset 0 0 1px 0 #424242;
    /* border-radius: 10px; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray.400;
    padding: 0.5rem;
    font-weight: bold;
    font-size: xs;
    text-transform: uppercase;
    text-align: center;
    overflow: auto;
}


 
.table-data td {
    text-align: center;
    /* box-shadow: inset 0 0 1px 0 #424242; */
    /* border-radius: 5px; */
    border-bottom: 1px solid gray;
    padding: 0.5rem;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .table-data {
        border: 0;
        width: 95%;
        background-color: #F4F7FE;
    }
  
    .table-data caption {
        font-size: 1.3em;
    }
    
    .table-data thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    
    .table-data tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em; 
        width: 100%;
    }
    
    .table-data td {
        display: grid;
        grid-template-columns: 0.5fr 1fr;
        font-size: .8em;
        text-align: right;
        width: 100%;
        overflow: hidden;
        box-shadow: none;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-radius: 0;
    }
    
    .table-data td::before {
        content: attr(data-label);
        float: left;
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .table-data td:first-child{
        border-top: 1px solid black;
        border-radius: 10px 10px 0 0;
    }

    .table-data td:last-child {
        border-bottom: 1px solid black;
        border-radius: 0 0 10px 10px;
    }
}
